//Todos desde el 14/11/2017 En caso de que se quiera personalizar en el futuro, quitar la siguiente línea.
DFI.DFI_configuration.services.webServiceAddLead ={tracking:true};
//Edreams
if(DFI.idTagDelio === "29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a3dbd53684b8b2b5ca41b93f1c0855b0d4a5da0cb53b0af4ae56b07131047f98d7d7f5fde35eabb223f462d7af532ddefc9d17f160543bfef6efe738c91e9f7fa25f74ee8d4167e1f10cacc2e28339c25"){
    DFI.DFI_configuration.services.dsaConfiguration.dsaMsgCallNow = "";
}

//Anular la anulación de eventos en in interferencia de formularios
if(DFI.idTagDelio === "29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a93030c25f551a86d5c62d2a9dcfb8d21fc640d7dad18d318bf189e9f8877ad6027db14b2017aeea6b43a8b65ef4ad0ff6e88ba83e4197248881abbf6c123e72428a80f47c0fa1ef1aa230dc208aae792" || DFI.idTagDelio ===  "29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a86aef19525f1138058f2c576818b2c270cd2064e0ccaab3782cd7d7793fa0809a66b854ec32bf237a8c595bf9e2389ddb05d49d9a2e872d4f78f6cadcdcfec6ece4c1470aebf2d740ad20761fa5e053a" || DFI.idTagDelio === "29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a265ff973d9a095e8dd4155d7d5f81f13cf8ee69616bc213dc8eccc83952860c8de01f6ae48d9d7e4506e4d981f1a7d3798627f29920a7b3ac9b96a709c86b9667987f400f2b7fecb38d60b61acc112ce" ){
    statusDFI.removeEventsForm = false;
}

// Mover al actualizar feature.
window.setVerifyLeadid = "NO";
if (DFI.DFI_configuration.options !== undefined) {
    if (DFI.DFI_configuration.options.verifyLeadId === "") {
        window.setVerifyLeadid = ""
    }
}
// Fin kiehls

//Vida
if (DFI.idTagDelio === "29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953adbb2b09c3283d76c0e0c28062915179be3fe595e8e22519b5ac75c97d4e5a52433e65bc3e789b78e9d8ed063e14f90b76b230e8a3222fed0f6f42af4f15356fd528a3ea59a20ac0e6ca867a161944b9e"){
    DFI.DFI_configuration.mappingLabels.byUrl = [
    {
        url: "https://promos.mutua.es/v-f/",
            labelsByUrls: [
                {
                    EConversion: { c_var_1: "Producto",c_var_2: "Site Conversion", c_var_3: "Paso"},
                    EVisits: { v_var_1: "producto", v_var_2: "Site"},
                },
                {
                    EConversion: { c_var_1: "Vida", c_var_2: "Landing", c_var_3: "BAK"},
                    EVisits: { v_var_1: "Vida", v_var_2: "landing"},
                }
            ]
    },
    {
        url: "https://promos.mutua.es/v-s-mob/",
            labelsByUrls: [
                {
                    EConversion: { c_var_1: "Producto", c_var_2: "Site Conversion", c_var_3: "Paso" },
                    EVisits: { v_var_1: "producto", v_var_2: "Site" },
                },
                {
                    EConversion: { c_var_1: "Vida", c_var_2: "Landing", c_var_3: "BAK" },
                    EVisits: { v_var_1: "Vida", v_var_2: "landing" },
                }
            ]
    }]

}

//México
if(DFI.idTagDelio === "29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a2d74f65110bcda8228348f4e53a39a6086be14afd5ab7ecebebfd721f0a4b05cde86cef921fcc210d865074e5dc0aaa9566b028b111a6b4246cb9d2c68712000fb47e693ad9eaaeeeb3ec5d42ee045b1"){
    DFI.DFI_configuration.mappingLabels.byUrl=[
        {url:"http://promosmovistar.com/pruebas/walmeric/mx-planes/index.php",
            labelsByUrls:[
                {
                EDeclaratives: { producto: "producto", ddibi: "ddibi", Landing_Page_Lead:"Landing_Page_Lead" },
                EConversion: {},
                EVisits:{v_var_4:"producto",v_var_5:"ddibi"},
                },
                {
                    EDeclaratives: { producto: "Pospago", ddibi: "si", Landing_Page_Lead: "http://promosmovistar.com/pruebas/walmeric/mx-planes/index.php" },
                EConversion: {},
                EVisits:{v_var_4:"Pospago",v_var_5:"si"},
                }
            ]
        },
        {url:"http://promosmovistar.com/pruebas/walmeric/mx-prepago/index.php",
            labelsByUrls:[
                {
                EDeclaratives: { producto: "producto", ddibi: "ddibi", Landing_Page_Lead: "Landing_Page_Lead"},
                EConversion: {},
                EVisits:{v_var_4:"producto",v_var_5:"ddibi"},
                },
                {
                    EDeclaratives: { producto: "Portabilidad pospago", ddibi: "si", Landing_Page_Lead: "http://promosmovistar.com/pruebas/walmeric/mx-prepago/index.php"},
                EConversion: {},
                EVisits:{v_var_4:"Portabilidad pospago",v_var_5:"si"},
                }
            ]
        },
        {url:"http://www.movistar.com.mx/promociones/cambiate-plan-con-equipo/",
            labelsByUrls:[
                {
                EDeclaratives: { producto: "producto", Landing_Page_Lead: "Landing_Page_Lead"},
                EConversion: {},
                EVisits:{v_var_4:"producto"},
                },
                {
                    EDeclaratives: { producto: "Pospago", Landing_Page_Lead: "http://www.movistar.com.mx/promociones/cambiate-plan-con-equipo/"},
                EConversion: {},
                EVisits:{v_var_4:"Pospago"},
                }
            ]
        },
        {url:"http://www.movistar.com.mx/promociones/cambiate-plan-sin-equipo/",
            labelsByUrls:[
                {
                EDeclaratives: { producto: "producto", Landing_Page_Lead: "Landing_Page_Lead"},
                EConversion: {},
                EVisits:{v_var_4:"producto"},
                },
                {
                    EDeclaratives: { producto: "Pospago", Landing_Page_Lead: "http://www.movistar.com.mx/promociones/cambiate-plan-sin-equipo/"},
                EConversion: {},
                EVisits:{v_var_4:"Pospago"},
                }
            ]
        },
        {url:"http://www.movistar.com.mx/promociones/plan-con-equipo/",
            labelsByUrls:[
                {
                EDeclaratives: { producto: "producto", Landing_Page_Lead: "Landing_Page_Lead"},
                EConversion: {},
                EVisits:{v_var_4:"producto"},
                },
                {
                    EDeclaratives: { producto: "Pospago", Landing_Page_Lead: "http://www.movistar.com.mx/promociones/plan-con-equipo/"},
                EConversion: {},
                EVisits:{v_var_4:"Pospago"},
                }
            ]
        },
        {url:"http://www.movistar.com.mx/promociones/plan-pago-anticipado/",
            labelsByUrls:[
                {
                EDeclaratives: { producto: "producto", Landing_Page_Lead: "Landing_Page_Lead"},
                EConversion: {},
                EVisits:{v_var_4:"producto"},
                },
                {
                    EDeclaratives: { producto: "Pospago", Landing_Page_Lead: "http://www.movistar.com.mx/promociones/plan-pago-anticipado/"},
                EConversion: {},
                EVisits:{v_var_4:"Pospago"},
                }
            ]
    },
        {url:"http://www.movistar.com.mx/promociones/plan-sin-equipo/",
        labelsByUrls:[
            {
            EDeclaratives: { producto: "producto", Landing_Page_Lead: "Landing_Page_Lead"},
            EConversion: {},
            EVisits:{v_var_4:"producto"},
            },
            {
                EDeclaratives: { producto: "Pospago", Landing_Page_Lead: "http://www.movistar.com.mx/promociones/plan-sin-equipo/"},
            EConversion: {},
            EVisits:{v_var_4:"Pospago"},
            }
        ]
    },
    {url:"http://www.movistar.com.mx/web/guest/descubre/portabilidad/cambiate-a-movistar/quiero-cambiarme/",
        labelsByUrls:[
            {
            EDeclaratives: { producto: "producto", Landing_Page_Lead: "Landing_Page_Lead"},
            EConversion: {},
            EVisits:{v_var_4:"producto"},
            },
            {
                EDeclaratives: { producto: "Portabilidad pospago", Landing_Page_Lead: "http://www.movistar.com.mx/web/guest/descubre/portabilidad/cambiate-a-movistar/quiero-cambiarme/"},
            EConversion: {},
            EVisits:{v_var_4:"Portabilidad pospago"},
            }
        ]
    },
    {url:"http://www2.movistar.com.mx/Form_Planes_LR/",
        labelsByUrls:[
            {
            EDeclaratives: { producto: "producto", Landing_Page_Lead: "Landing_Page_Lead"},
            EConversion: {},
            EVisits:{v_var_4:"producto"},
            },
            {
                EDeclaratives: { producto: "Pospago", Landing_Page_Lead: "http://www2.movistar.com.mx/Form_Planes_LR/"},
            EConversion: {},
            EVisits:{v_var_4:"Pospago"},
            }
        ]
    },
    {url:"http://www2.movistar.com.mx/Form_Planes_LR_ConEquipos/index.html?seccion=CambiateAMovistar",
        labelsByUrls:[
            {
            EDeclaratives: { producto: "producto", Landing_Page_Lead: "Landing_Page_Lead"},
            EConversion: {},
            EVisits:{v_var_4:"producto"},
            },
            {
                EDeclaratives: { producto: "Portabilidad pospago", Landing_Page_Lead: "http://www2.movistar.com.mx/Form_Planes_LR_ConEquipos/index.html?seccion=CambiateAMovistar"},
            EConversion: {},
            EVisits:{v_var_4:"Portabilidad pospago"},
            }
        ]
    },
    {
    url: "http://www2.movistar.com.mx/Form_Planes_LR_ConEquipos/",
    labelsByUrls: [
        {
            EDeclaratives: { producto: "producto", Landing_Page_Lead: "Landing_Page_Lead" },
            EConversion: {},
            EVisits: { v_var_4: "producto" },
        },
        {
            EDeclaratives: { producto: "Pospago", Landing_Page_Lead: "http://www2.movistar.com.mx/Form_Planes_LR_ConEquipos/" },
            EConversion: {},
            EVisits: { v_var_4: "Pospago" },
        }
    ]
},						
    {url:"http://www2.movistar.com.mx/Form_Planes_LR_Pago_Anticipado/",
        labelsByUrls:[
            {
            EDeclaratives: { producto: "producto", Landing_Page_Lead: "Landing_Page_Lead"},
            EConversion: {},
            EVisits:{v_var_4:"producto"},
            },
            {
                EDeclaratives: { producto: "Pospago", Landing_Page_Lead: "http://www2.movistar.com.mx/Form_Planes_LR_Pago_Anticipado/"},
            EConversion: {},
            EVisits:{v_var_4:"Pospago"},
            }
        ]
    },							
        {url:"https://www2.movistar.com.mx/Form_Prepago_LR/index.html?seccion=CambiateAMovistar&#",
        labelsByUrls:[
            {
            EDeclaratives: { producto: "producto", Landing_Page_Lead: "Landing_Page_Lead"},
            EConversion: {},
            EVisits:{v_var_4:"producto"},
            },
            {
                EDeclaratives: { producto: "Porta Prepago", Landing_Page_Lead: "https://www2.movistar.com.mx/Form_Prepago_LR/index.html?seccion=CambiateAMovistar&#"},
            EConversion: {},
            EVisits: { v_var_4:"Porta Prepago"},
            }
        ]
    },
    {
        url: "https://www2.movistar.com.mx/Form_Prepago_LR/index.html",
        labelsByUrls: [
            {
                EDeclaratives: { producto: "producto", Landing_Page_Lead: "Landing_Page_Lead" },
                EConversion: {},
                EVisits: { v_var_4: "producto" },
            },
            {
                EDeclaratives: { producto: "Prepago", Landing_Page_Lead: "https://www2.movistar.com.mx/Form_Prepago_LR/index.html" },
                EConversion: {},
                EVisits: { v_var_4: "Prepago" },
            }
        ]
    },						
    {url: "https://www2.movistar.com.mx/Form_Prepago_LR/",
        labelsByUrls: [
            {
                EDeclaratives: { producto: "producto", Landing_Page_Lead: "Landing_Page_Lead" },
                EConversion: {},
                EVisits: { v_var_4: "producto" },
            },
            {
                EDeclaratives: { producto: "Prepago", Landing_Page_Lead: "https://www2.movistar.com.mx/Form_Prepago_LR/"},
                EConversion: {},
                EVisits: { v_var_4: "Prepago" },
            }
        ]
    },						
    {url:"http://www.movistar.com.mx/promociones/cambiate-a-movistar/",
        labelsByUrls:[
            {
            EDeclaratives: { producto: "producto", Landing_Page_Lead: "Landing_Page_Lead"},
            EConversion: {},
            EVisits:{v_var_4:"producto"},
            },
            {
                EDeclaratives: { producto: "Porta Prepago", Landing_Page_Lead: "http://www.movistar.com.mx/promociones/cambiate-a-movistar/"},
            EConversion: {},
            EVisits:{v_var_4:"Porta Prepago"},
            }
        ]
    }, 
    {
        url: "http://www.movistar.com.mx/promociones/prepago/",
        labelsByUrls: [{
                EDeclaratives: {producto: "producto",Landing_Page_Lead: "Landing_Page_Lead"},
                EConversion: {},
                EVisits: {
                    v_var_4: "producto"
                },
            },
            {
                EDeclaratives: {producto: "Prepago",Landing_Page_Lead: "http://www.movistar.com.mx/promociones/prepago/"},
                EConversion: {},
                EVisits: {
                    v_var_4: "Prepago"
                },
            }
        ]
    },
        {
            url: "http://movistar-oferta.com/portabilidad-pospago/",
            labelsByUrls: [{
                EDeclaratives: { producto: "producto", Landing_Page_Lead: "Landing_Page_Lead", TipoAtencion: "TipoAtencion" },
                EConversion: {},
                EVisits: {
                    v_var_4: "producto"
                },
            },
            {
                EDeclaratives: { producto: "Pospago", Landing_Page_Lead: "http://movistar-oferta.com/portabilidad-pospago/",  TipoAtencion:"Pospago portabilidad" },
                EConversion: {},
                EVisits: {
                    v_var_4: "Pospago"
                },
            }
            ]
        },						
    ];
}		

//Fin México

// Chat test
if(DFI.idTagDelio === "___29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953aad40da14f6914cc89bf63a3be3f2842729b998cd444dd757a59c6f362458f92ce058488a4b3c274812bd5c92bcc123c1182e7e347ce1a251dfa3b06b461257a92b1ef1fc8e8afe700a10e4d7b055954b"){
    DFI.DFI_configuration.services.dsaConfiguration.webChat = {};
    DFI.DFI_configuration.services.dsaConfiguration.webChat.active= true;
    DFI.DFI_configuration.services.dsaConfiguration.webChat.chatApiKey= "07432c8108e56c4a098190c3a8b6a4bd";
    DFI.DFI_configuration.services.dsaConfiguration.webChat.chatIdservice= "39b36175b4417826120eef3188a32771";
    DFI.DFI_configuration.services.dsaConfiguration.webChat.visitVarChatUsage= "0";			

}
//desactivamos el chat

// if(DFI.DFI_configuration.services.dsaConfiguration.webChat !=undefined){
// 	DFI.DFI_configuration.services.dsaConfiguration.webChat.active= false;
// }
// Fin Chat test