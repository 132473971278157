export const support = function () {

    var idLead = "";

    /*
    |---------------------------------------------------------------------------
    | Inicia Ventana Modal
    |---------------------------------------------------------------------------
    */
    DFI.initModalView = function (personalizedHtmlCodeModal, personalizedHtmlCodeThankYouPage, personalizedHtmlCodeModalOutSchedule, personalizedHtmlCodeThankYouPageOutSchedule) {

        if (DFI.customElements === undefined) {
            DFI.customElements = {};
        }

        if (DFI.customElements.personalized === undefined) {
            DFI.customElements.personalized = {};
        }


        if (personalizedHtmlCodeModal === undefined) {
            personalizedHtmlCodeModal = "default";
        } else {
            DFI.customElements.personalized.personalizedHtmlCodeModal = personalizedHtmlCodeModal;
            DFI.customElements.personalized.personalizedHtmlCodeThankYouPage = personalizedHtmlCodeThankYouPage;
            DFI.customElements.personalized.personalizedHtmlCodeModalOutSchedule = personalizedHtmlCodeModalOutSchedule;
            DFI.customElements.personalized.personalizedHtmlCodeThankYouPageOutSchedule = personalizedHtmlCodeThankYouPageOutSchedule;
        }

        window.DFI.ModalView = new DMV();
        window.DFI.validSchedule = window.DFI.DFI_configuration.schedule;

        if (personalizedHtmlCodeModal === "default") {
            if (!DFI.scheduleValidAll(window.DFI.validSchedule)) {
                var template = "desktopFueraHorario";
            } else {
                var template = "desktop";
            }
        } else {
            //Personalized
            if (!DFI.scheduleValidAll(window.DFI.validSchedule)) {
                var template = "personalizedHtmlCodeModalOutSchedule";
            } else {
                var template = "personalizedHtmlCodeModal";
            }
        }


        DFI.ModalView.setContent(DFI.getTemplate(template));

        var CheckCreationDCL = setInterval(function () {
            if (window.DFI.DCL !== undefined) {

                //DCL.set.forms();

                clearInterval(CheckCreationDCL);
            }


        }, 100);


        statusDFI.ModalViewLoaded = true;
        DFI.updateForm();

        // Clase para levantar la modal .dModalForm
        var btnsModalForm = document.querySelectorAll('.dModalForm');

        for (var i = 0; i < btnsModalForm.length; i++) {
            btnsModalForm[i].addEventListener('click', DFI.ModalView.open, false);
        }

        btnsModalForm = document.querySelectorAll('.click2call_button');

        for (var i = 0; i < btnsModalForm.length; i++) {
            btnsModalForm[i].addEventListener('click', DFI.ModalView.open, false);
        }




        // Ocultamos los elementos que opcionálmente el usuario no quiere

        if (window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateEmailOptional !== true || window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvRingCMBOn !== true) {
            // dmv-div-email-id
            if (document.getElementById('dmv-div-email-id') !== null) {
                document.getElementById('dmv-div-email-id').style.display = 'none';
            }

            if (document.getElementById('dmv-email') !== null) {
                document.getElementById('dmv-email').style.display = 'none';
            }

        }

        if (window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvRingPoolOn !== true) {
            if (document.getElementById('llamanosId') !== null) {
                document.getElementById('llamanosId').style.display = 'none';
            }
        }

        if (window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateNameOptional !== true || window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvRingCMBOn !== true) {
            // dmv-div-email-id

            if (document.getElementById('dmv-div-name-id') !== null) {
                document.getElementById('dmv-div-name-id').style.display = 'none';
            }

            if (document.getElementById('nombre-DMV') !== null) {
                document.getElementById('nombre-DMV').style.display = 'none';
            }

        }

        // Unificación
        if (DFI.environment === 'mobile') {
            if (window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateNameOptional !== true || window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateEmailOptional !== true || window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvRingCMBOn !== true) {
                // dmv-div-email-id
                if (document.getElementById('dmv-content') !== null) {
                    document.getElementById('dmv-content').style.height = '';
                }

            }

            if ((window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateNameOptional !== true && window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateEmailOptional !== true) || window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvRingCMBOn !== true) {
                // dmv-div-email-id
                if (document.getElementById('dmv-content') !== null) {
                    document.getElementById('dmv-content').style.height = '';
                }

            }
        }
        //////////////

        if ((window.DFI.DFI_configuration.services.serviceList.ringPool.active !== true || window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.active !== true) && DFI.scheduleValidAll(window.DFI.validSchedule)) {

            if (document.getElementById('llamanosId') !== null) {
                document.getElementById('llamanosId').style.display = 'none';
            }

        }

        if (window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvRingCMBOn !== true) {

            if (document.getElementById('dmv-form') !== null) {
                document.getElementById('dmv-form').style.display = 'none';
            }

        }




        //Evento Onclick en el "hablamos?" de la pagina.
        var aElement = document.querySelectorAll('a[title="¿Te llamamos?"]');

        if (aElement.length !== 0) {

            aElement = aElement[0];

            aElement.href = "#";
            aElement.id = "callmenow";

            aElement.addEventListener("click", function () {
                if (DFI.ModalView !== undefined) {
                    DFI.ModalView.open();
                }
            });
        }

    };


    /*
    |---------------------------------------------------------------------------
    | Inicia Ringpool
    |---------------------------------------------------------------------------
    */
    DFI.initRingpool = function () {

        if (window.DFI.DFI_configuration.layers1 === undefined) {
            window.DFI.DFI_configuration.layers1 = [];
        }

        if (!window.DFI.DFI_configuration.layers1.includes(".drp-phone-new")) {
            if (window.DFI.DFI_configuration.layers1[0] !== "") {
                window.DFI.DFI_configuration.layers1.push(".drp-phone-new");
            } else {
                window.DFI.DFI_configuration.layers1[0] = ".drp-phone-new";
            }
        }


        // Init DRP
        window.DRP_ready = function () {

            window.DFI.labelsVarRingPool = {
                EDeclaratives: DFI.labels.EDeclaratives,
                EConversion: DFI.labels.EConversionDef,
                Evisit: DFI.labels.EVisitsDef
            };

            //Etiquetas de llamada del lead
            window.DFI.labelsVarRingPool.EDeclaratives["service_call_assigned_RP"] = window.DFI.servicioActivo1.serviceIdCallMeBack;
            window.DFI.labelsVarRingPool.EDeclaratives["service_call_priority"] = window.DFI.servicioActivo1.priority;
            window.DFI.labelsVarRingPool.EDeclaratives["service_call_ruleId"] = window.DFI.servicioActivo1.ruleId;
            window.DFI.labelsVarRingPool.EDeclaratives["service_call_name"] = window.DFI.servicioActivo1.serviceName;
            window.DFI.labelsVarRingPool.EDeclaratives["visual_idRule"] = window.DFI.resultShowRules.idRule;
            window.DFI.labelsVarRingPool.EDeclaratives["visual_idOrder"] = window.DFI.resultShowRules.order;
            window.DFI.labelsVarRingPool.EDeclaratives["visual_idDescription"] = window.DFI.resultShowRules.description;

            //Teléfono defecto DFI_client.defaultPhone
            if (typeof DFI_client === "object" && typeof DFI_client.defaultPhone === "string") {
                window.DFI.servicioActivo1.phoneCallCenterRingPool = DFI_client.defaultPhone;
            }

            var service = window.DFI.servicioActivo1;

            // Unificación
            if (DFI.environment === 'mobile') {
                var phoneElement = document.querySelectorAll(".text-telefonos");
                for (var i = 0, len = phoneElement.length; i < len; i++) {
                    phoneElement[i].className = window.DFI.DFI_configuration.layers1;

                }
            }
            //////////////

            var elements = window.DFI.DFI_configuration.layers1;
            if (window.DFI.resultShowRules.actionIsOkInterferePhone) {
                DFI.assignPhoneRingClass();
            }

            if (window.DFI.tempNotInterferePhone != true) {
                window.DFI.telefonoRingPoolActivo = window.DFI.servicioActivo1.phoneCallCenterRingPool;

                //Pool unavailabe
                if (typeof DFI_client === "object" && typeof DFI_client.defaultPhoneUnailable === "string") {
                    window.DFI.servicioActivo1.phoneCallCenterRingPool = DFI_client.defaultPhoneUnailable;
                }

                // window.DFI.optionalRingPool, si true se activa con reglas window.DFI.DFI_configuration.services.ringPool.active
                if ((window.DFI.DFI_configuration.services.ringPool.active && window.DFI.optionalRingPool !== true) ||
                    (window.DFI.DFI_configuration.services.ringPool.active && window.DFI.optionalRingPool === true &&
                        window.DFI.resultShowRules.actionIsOkInterferePhone)) {
                    var classTelefonos = document.getElementsByClassName("wal-drp-phone");
                    for (var i = 0, len = classTelefonos.length; i < len; i++) {
                        var elemento = classTelefonos[i];
                        elemento.href = "tel:" + service.phoneCallCenterRingPool;
                    }

                    // Con clase html-drp-phone solo el innerHTML
                    var htmlPhones = document.getElementsByClassName("html-drp-phone");
                    for (var i = 0, len = htmlPhones.length; i < len; i++) {
                        var elemento = htmlPhones[i];
                        elemento.innerHTML = DFI.buildMaskString(window.DFI.servicioActivo1.phoneCallCenterRingPool, window.DFI.DFI_configuration.services.serviceList.ringPool.phoneMask);
                    }

                    if (window.DFI.DFI_configuration.services.ringPool.active) {

                        var counter = 0;
                        var CheckNumberReceived = setInterval(function () {

                            if (window.DFI.ringPoolCompleted != true && counter > 1) {
                                clearInterval(CheckNumberReceived);
                                var tagVisitTemp = window.DFI.DFI_configuration.idTagVisit;
                                var htmlPhonesDrp = document.querySelectorAll(window.DFI.DFI_configuration.layers1[0]);
                                for (var i = 0, len = htmlPhonesDrp.length; i < len; i++) {
                                    var elemento = htmlPhonesDrp[i];
                                    elemento.innerHTML = DFI.buildMaskString(window.DFI.servicioActivo1.phoneCallCenterRingPool, window.DFI.DFI_configuration.services.serviceList.ringPool.phoneMask);
                                    elemento.href = "tel:" + service.phoneCallCenterRingPool;
                                }
                            } else {
                                counter++;
                                if (counter > 3) {
                                    clearInterval(CheckNumberReceived);
                                }
                            }

                        }, 300)

                    }
                }

                // //Teléfono defecto DFI_client.defaultPhone
                // if (typeof DFI_client === "object" && typeof DFI_client.defaultPhone === "string") {
                //     window.DFI.servicioActivo1.phoneCallCenterRingPool = DFI_client.defaultPhone;
                // }

                window.DFI.ringPoolInit = true;
                //Actualizamos etiquetas declarativas cuando tengamos el idVisitor disponible

                let loadupdaVisitVarsUpdate = setInterval(function () {

                    if (typeof Piwik === "object" && typeof Piwik.getAsyncTracker() === "object" && typeof Piwik.getAsyncTracker().getVisitorId() === "string"
                    ) {
                        clearInterval(loadupdaVisitVarsUpdate);
                        DAL.Ajax({
                            type: 'jsonp',
                            url: DFI.global.updateVisitLabelByVisitorId,
                            data: {
                                data: encodeURIComponent(JSON.stringify(DFI.labels.EVisitsDef)),
                                idTag: DFI.idTagDelio,
                                idVisitor: Piwik.getAsyncTracker().getVisitorId(),
                                format: "jsonp",
                            }
                        })
                    }

                }, 100);

                DRP.init({
                    defaultPhone: window.DFI.servicioActivo1.phoneCallCenterRingPool,
                    capa: window.DFI.DFI_configuration.layers1,
                    labels: window.DFI.labelsVarRingPool,
                    campaignid: service.serviceIdRingPool,
                    reqPhone: function (data) {
                        window.DFI.ringPoolCompleted = true;

                        // Unificación
                        if (DFI.environment === 'mobile') {
                            // Para el caso de que el DSA esté en el pie.
                            if (window.DFI.mostrarPie === "ringpool+CMB") {
                                document.getElementById("ringPoolCha").innerHTML = '<img  src="https://t.womtp.com/slider/c/delio-lm/dfi/assets/img/phone_alone.png" alt="Smart Buttom" style="">' + window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaMsgCallNow;
                            }
                            if (window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaMsgCallNow === "" && window.DFI.mostrarPie === "ringpool+CMB") {
                                document.getElementById("ringPoolCha").innerHTML = '<img  src="https://t.womtp.com/slider/c/delio-lm/dfi/assets/img/phone_alone.png" alt="Smart Buttom" style="">' + data.telefono.substring(0, 2) + " " + data.telefono.substring(2, 5) + " " + data.telefono.substring(5, 7) + " " + data.telefono.substring(7);
                            }

                            if (window.DFI.mostrarPie === "ringpool") {
                                document.getElementById("ringPoolCha").innerHTML = '<img  src="https://t.womtp.com/slider/c/delio-lm/dfi/assets/img/phone_alone.png" alt="Smart Buttom" style="">' + " " + window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaMsgCallNow + " " + data.telefono.substring(0, 2) + " " + data.telefono.substring(2, 5) + " " + data.telefono.substring(5, 7) + " " + data.telefono.substring(7);
                            }
                        }
                        //////////////


                        DFI.consoleLogDelio(data);
                        // window.DFI.optionalRingPool, si true se activa con reglas window.DFI.DFI_configuration.services.ringPoolwindow.DFI.DFI_configuration.services.ringPool.active
                        if ((window.DFI.DFI_configuration.services.ringPool.active && window.DFI.optionalRingPool !== true) ||
                            (window.DFI.DFI_configuration.services.ringPool.active && window.DFI.optionalRingPool === true &&
                                window.DFI.resultShowRules.actionIsOkInterferePhone)) {
                            // Con clase wal-drp-phone solo el href
                            for (var i = 0, len = classTelefonos.length; i < len; i++) {
                                var elemento = classTelefonos[i];
                                elemento.href = "tel:" + data.telefono;

                            }

                            // Con clase html-drp-phone solo el innerHTML
                            var htmlPhones = document.getElementsByClassName("html-drp-phone")
                            for (var i = 0, len = htmlPhones.length; i < len; i++) {
                                var elemento = htmlPhones[i];
                                elemento.innerHTML = DFI.buildMaskString(data.telefono, window.DFI.DFI_configuration.services.serviceList.ringPool.phoneMask);
                            }
                        }



                        window.DFI.telefonoRingPoolActivo = data.telefono;


                    },
                    called: function (data) {
                        statusDFI.uid = data;
                        window.c_var_9 = "RingPool";
                        if (typeof DFI_client.customResponseOKEvent != "undefined") {
                            DFI_client.customResponseOKEvent();
                        }
                        DFI.loadConversion();
                    },
                    idTag: window.DFI.DFI_configuration.idTagDelioClient,
                    maskPhone: window.DFI.DFI_configuration.services.serviceList.ringPool.phoneMask
                });
            }
        };

        // Carga de Ringpool Script
        DAL.Ajax({ type: "script", url: DFI.global.drp });
        statusDFI.initRingpoolCompleted = true;

    };
    /*
    /---------------------------------------------------------------------------
    Refresh para Ringpool
    /---------------------------------------------------------------------------
    */
    DFI.refreshRingpool = function () {

        if (window.DFI.DFI_configuration.layers1 === undefined) {
            window.DFI.DFI_configuration.layers1 = [];
        }

        if (!window.DFI.DFI_configuration.layers1.includes(".drp-phone-new")) {
            if (window.DFI.DFI_configuration.layers1[0] !== "") {
                window.DFI.DFI_configuration.layers1.push(".drp-phone-new");
            } else {
                window.DFI.DFI_configuration.layers1[0] = ".drp-phone-new";
            }
        }


        window.DFI.labelsVarRingPool = {
            EDeclaratives: DFI.labels.EDeclaratives,
            EConversion: DFI.labels.EConversionDef,
            Evisit: DFI.labels.EVisitsDef
        };

        //Etiquetas de llamada del lead
        window.DFI.labelsVarRingPool.EDeclaratives["service_call_assigned_RP"] = window.DFI.servicioActivo1.serviceIdCallMeBack;
        window.DFI.labelsVarRingPool.EDeclaratives["service_call_priority"] = window.DFI.servicioActivo1.priority;
        window.DFI.labelsVarRingPool.EDeclaratives["service_call_ruleId"] = window.DFI.servicioActivo1.ruleId;
        window.DFI.labelsVarRingPool.EDeclaratives["service_call_name"] = window.DFI.servicioActivo1.serviceName;
        window.DFI.labelsVarRingPool.EDeclaratives["visual_idRule"] = window.DFI.resultShowRules.idRule;
        window.DFI.labelsVarRingPool.EDeclaratives["visual_idOrder"] = window.DFI.resultShowRules.order;
        window.DFI.labelsVarRingPool.EDeclaratives["visual_idDescription"] = window.DFI.resultShowRules.description;


        var service = window.DFI.servicioActivo1;

        // Unificación
        if (DFI.environment === 'mobile') {
            var phoneElement = document.querySelectorAll(".text-telefonos");
            for (var i = 0, len = phoneElement.length; i < len; i++) {
                phoneElement[i].className = window.DFI.DFI_configuration.layers1;
            }
        }
        //////////////

        var elements = window.DFI.DFI_configuration.layers1;
        if (window.DFI.resultShowRules.actionIsOkInterferePhone) {
            DFI.assignPhoneRingClass();
        }

        if (window.DFI.tempNotInterferePhone != true) {
            window.DFI.telefonoRingPoolActivo = window.DFI.servicioActivo1.phoneCallCenterRingPool;

            //Pool unavailabe
            if (typeof DFI_client === "object" && typeof DFI_client.defaultPhoneUnailable === "string") {
                window.DFI.servicioActivo1.phoneCallCenterRingPool = DFI_client.defaultPhoneUnailable;
            }

            // window.DFI.optionalRingPool, si true se activa con reglas window.DFI.DFI_configuration.services.ringPool.active
            // if ((window.DFI.DFI_configuration.services.ringPool.active && window.DFI.optionalRingPool !== true) ||
            //     (window.DFI.DFI_configuration.services.ringPool.active && window.DFI.optionalRingPool === true &&
            //         window.DFI.resultShowRules.actionIsOkInterferePhone)) {
            //     var classTelefonos = document.getElementsByClassName("wal-drp-phone");
            //     for (var i = 0, len = classTelefonos.length; i < len; i++) {
            //         var elemento = classTelefonos[i];
            //         elemento.href = "tel:" + service.phoneCallCenterRingPool;
            //     }

            //     // Con clase html-drp-phone solo el innerHTML
            //     var htmlPhones = document.getElementsByClassName("html-drp-phone");
            //     for (var i = 0, len = htmlPhones.length; i < len; i++) {
            //         var elemento = htmlPhones[i];
            //         elemento.innerHTML = DFI.buildMaskString(window.DFI.servicioActivo1.phoneCallCenterRingPool, window.DFI.DFI_configuration.services.serviceList.ringPool.phoneMask);
            //     }

            //     if (window.DFI.DFI_configuration.services.ringPool.active) {

            //         var counter = 0;
            //         var CheckNumberReceived = setInterval(function () {

            //             if (window.DFI.ringPoolCompleted != true && counter > 1) {
            //                 clearInterval(CheckNumberReceived);
            //                 var tagVisitTemp = window.DFI.DFI_configuration.idTagVisit;
            //                 var htmlPhonesDrp = document.querySelectorAll(window.DFI.DFI_configuration.layers1[0]);
            //                 for (var i = 0, len = htmlPhonesDrp.length; i < len; i++) {
            //                     var elemento = htmlPhonesDrp[i];
            //                     elemento.innerHTML = DFI.buildMaskString(window.DFI.servicioActivo1.phoneCallCenterRingPool, window.DFI.DFI_configuration.services.serviceList.ringPool.phoneMask);
            //                     elemento.href = "tel:" + service.phoneCallCenterRingPool;
            //                 }
            //             } else {
            //                 counter++;
            //                 if (counter > 3) {
            //                     clearInterval(CheckNumberReceived);
            //                 }
            //             }

            //         }, 300)

            //     }
            // }

            //Teléfono defecto DFI_client.defaultPhone
            // if (typeof DFI_client === "object" && typeof DFI_client.defaultPhone === "string") {
            //     window.DFI.servicioActivo1.phoneCallCenterRingPool = DFI_client.defaultPhone;
            // }

            window.DFI.ringPoolInit = true;
            //Actualizamos etiquetas declarativas cuando tengamos el idVisitor disponible

            let loadupdaVisitVarsUpdate = setInterval(function () {

                if (typeof Piwik === "object" && typeof Piwik.getAsyncTracker() === "object" && typeof Piwik.getAsyncTracker().getVisitorId() === "string"
                ) {
                    clearInterval(loadupdaVisitVarsUpdate);
                    DAL.Ajax({
                        type: 'jsonp',
                        url: DFI.global.updateVisitLabelByVisitorId,
                        data: {
                            data: encodeURIComponent(JSON.stringify(DFI.labels.EVisitsDef)),
                            idTag: DFI.idTagDelio,
                            idVisitor: Piwik.getAsyncTracker().getVisitorId(),
                            format: "jsonp",
                        }
                    })
                }

            }, 100);
        }
        DRP.refreshService({
            campaignId:service.serviceIdRingPool,
            capa:window.DFI.DFI_configuration.layers1,
            labels: window.DFI.labelsVarRingPool,
            defaultPhone:window.DFI.servicioActivo1.phoneCallCenterRingPool
        });


    };

    /*
    |---------------------------------------------------------------------------
    | Inicia Delio Client
    |---------------------------------------------------------------------------
    */
    DFI.initC2C = function () {

        window.DCL_success = function (response) {

            if (typeof DFI_client.customThankU === "function") {
                DFI.ModalView.close();
            }

            //window.DFI.customThanks Html de la template de gracias
            //
            if (DFI.customElements === undefined) {
                DFI.customElements = {};
                DFI.customElements.personalized = {};
            }

            var template;
            if (DFI.customElements.personalized.personalizedHtmlCodeModal === "default" || DFI.customElements.personalized.personalizedHtmlCodeModal === undefined) {
                if (!DFI.scheduleValidAll(window.DFI.validSchedule)) {
                    template = "gracias_fueraHorario";
                } else {
                    template = "gracias";
                }
            } else {
                if (!DFI.scheduleValidAll(window.DFI.validSchedule)) {
                    template = "personalizedHtmlCodeThankYouPageOutSchedule";
                } else {
                    template = "personalizedHtmlCodeThankYouPage";
                }
            }

            //thanksPage =true

            DFI.ModalView.setContent(DFI.getTemplate(template));

            statusDFI.ModalViewThanksLoaded = true;
            // Ocultamos los elementos que opcionálmente el usuario no quiere
            // document.getElementById( 'elemtId' ).style.display = 'none';
            // window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateEmailOptional
            // window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateNameOptional

            if (typeof DFI_client.customThankU === "function") {
                var resultLead = response.form;
                DFI_client.response = response;
                resultLead.onSchedule = DFI.scheduleValidAll(window.DFI.validSchedule);
                if (response.result === 'OK') {
                    resultLead.registered = true;
                } else {
                    resultLead.registered = false;
                }
                DFI_client.customThankU(resultLead);
            } else {
                if (DFI.ModalView !== undefined) {
                    DFI.ModalView.open();
                }
            }



            //Seteamos que  el lead ha completado el formulario.
            //window.DFI.leadSent = { send: "true", idLead: idLead };

            // En el caso que se obtenga una respuesta satisfactoria por parte del servicio DCL, se procede a realizar la conversión.
            if (response.result === 'OK') {
                DFI.loadConversion(response.leadId);
                // Grabamos en session storage el idlead, el motivo es que lo necesitamos en caso de que el cliente contacte con el CC y después prosiga con la venta.
                var sessionLeadid = { 'leadId': response.leadId };
                sessionStorage.setItem('leadId', JSON.stringify(sessionLeadid));

                var idlead = response.leadId;
                document.cookie = "wm_user=" + idlead + "; max-age=" + 60 * 30 + "; path=/ ; domain=";

                var elements = document.getElementsByClassName("fondo-back");
                for (var i = 0; i < elements.length; i++) {
                    elements[i].style.width = ("auto");
                }

                // Unificacion
                if (DFI.environment === 'mobile') {
                    window.DFI.Cobrowser_config = {};
                }
                //////////////

            }

            //Lanzar funciones personalizadas en función resultado llamada
            if (typeof DFI_client.customResponseOKEvent != "undefined") {
                DFI_client.customResponseOKEvent(response);
            }

            if (typeof DFI_client.customResponseKOEvent != "undefined") {
                DFI_client.customResponseKOEvent(response);
            }


        };

        /**
         * Variable de configuración 
         */

        // TODO: debería de ser window.DFI.clientConfig
        window.clientConfig = {
            timeoutTooltip: 3,
            lang: window.DFI.servicioPais,
            pos: 'bottom',
            sendHidden: true,
            type: 'element',
            validate: true,
            validation: {
                notification: {
                    type: 'element'
                }
            }
        };
        var setShowRulesNewResults = window.DFI.resultShowRules;

        if ((window.DFI.resultShowRules.actionIsOkDSA === "show" || document.querySelectorAll('.dModalForm').length > 0)
            && window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaOn === true
            || window.DFI.resultShowRules.whoValidate === 1) {
            DAL.Ajax({ type: "script", url: DFI.global.dcl + window.DFI.DFI_configuration.idTagDelioClient });
            statusDFI.dclLoaded = true;
        }
        if (setShowRulesNewResults.actionIsOkInterfereForm && window.DFI.tempNotInterferePhone != true) {
            window.DFI.DFI_configuration.services.userForm = {};
            window.DFI.DFI_configuration.services.userForm.formPhoneName = setShowRulesNewResults.fieldNamePhone;
            window.DFI.DFI_configuration.services.userForm.formPolicyName = setShowRulesNewResults.fieldNameCheckLegalConditions;
            if (window.DFI.avoidInterference !== "true") {
                var stateCheck = setInterval(function () {
                    if (document.readyState === 'complete') {
                        clearInterval(stateCheck);
                        //DFI.addDelioClientToForms(); 
                        statusDFI.formsUpdated = DFI.whoInterfere();
                        var stateCheck1 = setInterval(function () {
                            if (statusDFI.formsUpdated) {
                                clearInterval(stateCheck1);
                                // Carga de DElio Client Script
                                if (window.DFI.resultShowRules.actionIsOkDSA !== "show") {
                                    if (statusDFI.dclLoaded != true) {
                                        DAL.Ajax({ type: "script", url: DFI.global.dcl + window.DFI.DFI_configuration.idTagDelioClient });
                                    }
                                    var checkLoadedDCLcounter = 0;
                                    var checkLoadedDCL = setInterval(function () {
                                        checkLoadedDCLcounter++;
                                        if (typeof DCL === "object" && typeof DCL.set === "object" && typeof DCL.set.forms === "function") {
                                            DCL.set.forms();

                                            //Chequeamos que se ha añadido el idTag.
                                            DFI.addIdtagToFormsWhenNotRight();

                                            clearInterval(checkLoadedDCL);
                                        }
                                        if (checkLoadedDCLcounter > 100) {
                                            clearInterval(checkLoadedDCL);
                                        }
                                    }, 50);
                                } else {
                                    DCL.set.forms();
                                    //Chequeamos que se ha añadido el idTag.
                                    DFI.addIdtagToFormsWhenNotRight();
                                }

                            }
                        }, 100);

                    }
                }, 100);


            }
        }

        if (window.DFI.DFI_configuration.services.webServiceAddLead != undefined && window.DFI.DFI_configuration.services.webServiceAddLead != "undefined") {
            if (window.DFI.DFI_configuration.services.webServiceAddLead.tracking === true) {
                var checkPiwik = setInterval(function () {
                    if (typeof Piwik != "undefined" && typeof Piwik != undefined) {
                        if (typeof Piwik.getAsyncTracker().getVisitorId() != "undefined" && typeof Piwik.getAsyncTracker().getVisitorId() != undefined) {
                            if (window.DFI.womtp && window.DFI.womtp.getAsyncTracker) {
                                window.DFI.idLead = window.DFI.womtp.getAsyncTracker().getVisitorId();
                            } else if (window.DFI.Piwik) {
                                window.DFI.idLead = window.DFI.Piwik.getAsyncTracker().getVisitorId();
                            }
                            DFI.consoleLogDelio(window.DFI.idLead);
                            DFI.addIdleadAllForm(window.DFI.idLead);
                            clearInterval(checkPiwik);
                        }
                    }

                }, 3000);
            }
        }


    };

    /*
    |---------------------------------------------------------------------------
    | Añade etiquetas declarativas y de conversion a dmv-form
    |---------------------------------------------------------------------------
    */

    DFI.updateForm = function () {

        var declaratives = DFI.labels.EDeclaratives;
        var conversion = DFI.labels.EConversion;
        var form = document.getElementById('dmv-form');
        if (form !== null) {
            var inputName;
            var inputValue;

            for (var i in declaratives) {
                inputName = i;
                inputValue = declaratives[i];
                form.innerHTML += "<input type='hidden' name='" + inputName + "' value='" + inputValue + "'>";
            }

            for (var c in conversion) {
                inputName = c;
                inputValue = conversion[c];
                form.innerHTML += "<input type='hidden'  class='wg-cvar'  name='" + inputName + "' value='" + inputValue + "'>";
            }

        }
        statusDFI.updateForm = true;

    };


    // Inicialización de servicios

    DFI.initFormsAndResources = function () {
        window.DFI.resultShowRules = DFI.setShowRulesScoring();

        if (window.DFI.resultShowRules.modalId === -1) {
            if (typeof window.DFI.DFI_configuration.modals !== "object") {
                window.DFI.DFI_configuration.modals = [];
            }
            var defaultModal = {
                "id": "3",
                "name": "aaa",
                "config": {
                    "active": true,
                    "dmvStyles": "",
                    "dmvRingPoolOn": true,
                    "dmvRingCMBOn": true,
                    "dmvTemplateTitle": "",
                    "dmvTemplateSubTitle": "",
                    "dmvTemplateCtaCmb": "",
                    "dmvTemplatePlaceholderName": "",
                    "dmvTemplateNameOptional": false,
                    "dmvTemplatePlaceholderPhone": "",
                    "dmvTemplatePlaceholderEmail": "",
                    "dmvTemplateEmailOptional": false,
                    "dmvTemplatePrivacyUrl": "",
                    "dmvTemplatePrivacyText": "",
                    "dmvTemplateAditionalText": "",
                    "dmvTemplateGracias": "",
                    "templateCallMeText": "",
                    "dmvArrTemplatePrivacyText": [],
                    "inactivityTime": 15,
                    "inactivityMode": false,
                    "dmvTemplateTextRingPool": "",
                    "dmvModalType": "personal",
                    "dmvCustom": {
                        "HTMLmodalInShedule": "<div> Please go to Delio 1 click to configure a default modal</div>",
                        "HTMLmodalOutShedule": "<div> Please go to Delio 1 click to configure a default modal</div>",
                        "HTMLthankyouInShedule": "<div> Please go to Delio 1 click to configure a default modal</div>",
                        "HTMLthankyouOutShedule": "<div> Please go to Delio 1 click to configure a default modal</div>",
                        "sizeDesktop": "auto",
                        "heightDesktop": null,
                        "widthDesktop": null,
                        "sizeMobile": "auto",
                        "heightMobile": null,
                        "widthMobile": null
                    }
                }
            };
            window.DFI.DFI_configuration.modals.push(defaultModal);
            window.DFI.DFI_configuration.modals[window.DFI.DFI_configuration.modals.length - 1].id = window.DFI.DFI_configuration.modals.length.toString();
            window.DFI.resultShowRules.modalId = window.DFI.DFI_configuration.modals.length;
            window.DFI.resultShowRules.modalIndex = "0";
        } else{
            window.DFI.DFI_configuration.modals.forEach((element,idx) => {
                if(window.DFI.resultShowRules.modalId === parseInt(element.id) ){
                    window.DFI.resultShowRules.modalIndex = parseInt(idx);
                }             
            });
        }
        if (window.DFI.resultShowRules.dsaId === -1) {
            if (typeof window.DFI.DFI_configuration.DSAs !== "object") {
                window.DFI.DFI_configuration.DSAs = [];
            }
            var defaultDsa = {
                "id": "1",
                "name": "DSA Default - modificada",
                "config": {
                    "dsaOn": false,
                    "dsaX": "right",
                    "dsaY": "float",
                    "dsaFactor": 0.2,
                    "dsaStyles": "https://t.womtp.com/slider/i/delio-lm/dfi/assets/stylesheets/test1.css",
                    "dsaImage": "https://t.womtp.com/slider/c/delio-lm/dfi/assets/img/agente_amarillo.png",
                    "dsaClose": "https://delio-lm.com/delio-lite/delio-lm/dfi/assets/img/btn_close_yellow_white.png",
                    "dsaMsgCallNow": "Llama ahora",
                    "dsaWeCallYou": "Te llamamos",
                    "dsaFrontFormatServices": {
                        "status": false,
                        "headertext": "",
                        "ctaText": "",
                        "webChat": false,
                        "whatsApp": false,
                        "CMB": false,
                        "headerBackgroundColor": "#428600",
                        "headerColor": "#ffffff",
                        "color": "#333333"
                    },
                    "dsaType": "delio",
                    "dsaHTML": ""
                }
            };
            window.DFI.DFI_configuration.DSAs.push(defaultDsa);
            window.DFI.DFI_configuration.DSAs[window.DFI.DFI_configuration.DSAs.length - 1].id = window.DFI.DFI_configuration.DSAs.length.toString();
            window.DFI.resultShowRules.dsaId = window.DFI.DFI_configuration.DSAs.length;
        }

        var validationRules = window.DFI.resultShowRules;
        //Cargamos hojas de estilos personalizadas de las modales
        if (window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.active === true &&
            window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvStyles !== undefined &&
            window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvStyles.length > 0) {
            DAL.Ajax({ type: "style", url: window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvStyles });

        }

        //Cargamos hojas de estilos de los DSAs
        if (window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaOn === true &&
            window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaStyles !== undefined &&
            window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaStyles.length > 0) {
            DAL.Ajax({ type: "style", url: window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaStyles });

        }



        statusDFI.gatewaysLoaded = true;
        window.DFI.validSchedule = window.DFI.DFI_configuration.schedule;
        if ((validationRules.actionIsOkDSA !== "hide" || validationRules.actionIsOkInterfereForm !== false || document.querySelectorAll('.dModalForm').length > 0 || document.querySelectorAll('.click2call_button').length > 0 || document.getElementById("modal-delio") !== null) && window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.active === true) {
            DFI.initModalView();
            statusDFI.initModalView = true;
            // Solo aplica si se carga la modal
            if (document.getElementById("idservice") !== null) {
                if (window.DFI.servicioActivo4 !== false) {
                    document.getElementById("idservice").value = window.DFI.servicioActivo;
                } else {
                    document.getElementById("idservice").value = "";
                }
            }

            if (document.getElementById("idservice1") !== null) {
                document.getElementById("idservice1").value = window.DFI.servicioActivo1.priority;
            }

            if (document.getElementById("idservice2") !== null) {
                document.getElementById("idservice2").value = window.DFI.servicioActivo1.ruleId;
            }

            if (document.getElementById("idservice3") !== null) {
                document.getElementById("idservice3").value = window.DFI.servicioActivo1.serviceNameCallMeBack;
            }

            if (document.getElementById("idservice4") !== null) {
                document.getElementById("idservice4").value = window.DFI.resultShowRules.idRule
            }
            if (document.getElementById("idservice5") !== null) {
                document.getElementById("idservice5").value = window.DFI.resultShowRules.order;
            }
            if (document.getElementById("idservice6") !== null) {
                document.getElementById("idservice6").value = window.DFI.resultShowRules.description;
            }
        }

        if (window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.active === true) {
            DFI.inactivityActions(window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config);
        }

        // Parte de carga del chat. Lo cambiamos de posición ya que el DSA puede necesitar que esté cargado.

        //&& DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvModalType != "personal"

        if (window.DFI.resultShowRules.actionIsOkDSA !== "hide" && window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaOn !== false) {
            //Desaparece la condición DFI.DFI_configuration.DSAs.dsaOn === true &&
            //Para el DSA personalizado, no se permite en el front decidir donde se situa el DSA. por defecto lo ponemos flotante.
            // Se tiene que elegir desde el front.
            if (window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaType === "personal") {
                window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaY = "float";
            }


            if ( // Unificación
                (DFI.environment === 'desktop' && (window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaY !== "top1" && window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaY !== "bottom1")) ||
                (DFI.environment === 'mobile' && (window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaY !== "top" && window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaY !== "bottom"))
                //////////////
            ) {
                var setShowRulesNewResults = window.DFI.resultShowRules;
                if (setShowRulesNewResults.dsaId !== undefined && setShowRulesNewResults.dsaId !== null && setShowRulesNewResults.dsaId !== "undefined" && setShowRulesNewResults.dsaId !== "null" && window.DFI.DFI_configuration.DSAs[setShowRulesNewResults.dsaId - 1].config.dsaType !== "delio") {
                    var dsaTemplate = window.DFI.DFI_configuration.DSAs[setShowRulesNewResults.dsaId - 1].config.dsaHTML;

                    DFI.personalizedDSA(dsaTemplate, "82%", "400px", 0.2, true, "walmeric_boton_c2c");
                    var myVar = setInterval(function () {
                        if (document.getElementsByClassName("dsa-newId").length > 0) {
                            clearInterval(myVar);
                            document.getElementById("dsa-close").addEventListener("click",
                                function () {
                                    document.getElementById("dsa").remove();

                                }
                                , false);

                        }

                    }, 300);
                } else {
                    window.DFI.SmartAccess = new DSA({
                        dsaMessage: {
                            text: '',
                            position: 'bottom'
                        },
                        dsaImage: window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaImage,
                        closeImage: window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaClose,
                        position: {
                            x: "right",
                            y: "bottom",
                            factor: 0.2
                        },
                        onclick: function () {
                            if (DFI.ModalView !== undefined) {
                                DFI.ModalView.open();
                            }
                        }
                    });
                }

            } else if (window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaOn === true) {
                // Barrasuperior o inferior

                var dmv_bar = document.createElement("div");
                dmv_bar.id = "dmv_bar";
                window.DFI.validSchedule = window.DFI.DFI_configuration.schedule;

                // Unificación
                if (DFI.environment === 'desktop') {
                    if (DFI.scheduleValidAll(window.DFI.validSchedule)) {
                        var dmv_bar1 = '<a href="" class="drp-phone-new wal-drp-phone" id="ringPoolCha"></a>' +
                            '<img src="' + window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaImage + ' " alt="Smart Buttom" style="">';
                        document.getElementsByTagName('body')[0].appendChild(dmv_bar);
                        document.getElementById("dmv_bar").innerHTML = dmv_bar1;
                        var telProv = document.getElementById("ringPoolCha").innerHTML;
                        telProv = telProv.replace(' ', '');
                        document.getElementById("ringPoolCha").href = telProv;
                        var dmvBarButton = document.getElementById("dmv_bar");
                    } else {
                        var dmv_bar1 = '<img src="' + window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaImage + ' " alt="Smart Buttom" style="">';
                        document.getElementsByTagName('body')[0].appendChild(dmv_bar);
                        document.getElementById("dmv_bar").innerHTML = dmv_bar1;
                        var dmvBarButton = document.getElementById("dmv_bar");
                    }

                    dmvBarButton.onclick = function () {
                        if (DFI.ModalView !== undefined) {
                            DFI.ModalView.open();
                        }
                    };
                } else {
                    if (DFI.scheduleValidAll(window.DFI.validSchedule) && window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.active && window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvRingCMBOn) {
                        // Se tienen que dibujar teléfono y CMB
                        if (window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaMsgCallNow === undefined) {
                            window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaWeCallYou = "Te llamamos ahora";
                        }
                        var dmv_bar1 = '<a href="" class="drp-phone-new wal-drp-phone" id="ringPoolCha"></a>' +
                            '<a  id="dmvText"><img  src="https://t.womtp.com/slider/c/delio-lm/dfi/assets/img/phone_alone_cmb.png" alt="Smart Buttom" style="">' + window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaWeCallYou + '</a>';
                        document.getElementsByTagName('body')[0].appendChild(dmv_bar);
                        document.getElementById("dmv_bar").innerHTML = dmv_bar1;
                        var telProv = document.getElementById("ringPoolCha").innerHTML;
                        telProv = telProv.replace(' ', '');
                        document.getElementById("ringPoolCha").href = telProv;
                        if (window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaMsgCallNow === undefined) {
                            window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaMsgCallNow = "";
                        }
                        document.getElementById("ringPoolCha").innerHTML = '<img  src="https://t.womtp.com/slider/c/delio-lm/dfi/assets/img/phone_alone.png" alt="Smart Buttom" style="">' + window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaMsgCallNow;
                        window.DFI.mostrarPie = "ringpool+CMB";
                        var dmvBarButton = document.getElementById("dmv_bar");
                        var dmvBarButton1 = document.getElementById("dmvText");
                        dmvBarButton1.onclick = function () {
                            if (DFI.ModalView !== undefined) {
                                DFI.ModalView.open();
                            }
                        };
                    } else if (DFI.scheduleValidAll(window.DFI.validSchedule) && window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.active) {
                        // Se tienen que dibujar teléfono 
                        var dmv_bar1 = '<a class="drp-phone-new wal-drp-phone" id="ringPoolCha"></a>'
                        document.getElementsByTagName('body')[0].appendChild(dmv_bar);
                        document.getElementById("dmv_bar").innerHTML = dmv_bar1;
                        var telProv = document.getElementById("ringPoolCha").innerHTML;
                        telProv = telProv.replace(' ', '');
                        document.getElementById("ringPoolCha").href = telProv;
                        if (window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaMsgCallNow === undefined) {
                            window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaMsgCallNow = "";
                        }
                        document.getElementById("ringPoolCha").innerHTML = '<img  src="https://t.womtp.com/slider/c/delio-lm/dfi/assets/img/phone_alone.png" alt="Smart Buttom" style="">' + window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaMsgCallNow + telProv;
                        document.getElementById("ringPoolCha").style.width = "100%";
                        window.DFI.mostrarPie = "ringpool";
                        var dmvBarButton = document.getElementById("dmv_bar");
                    } else if (window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvRingCMBOn) {
                        if (window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaMsgCallNow === undefined) {
                            window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaWeCallYou = "";
                        }
                        var dmv_bar1 = '<a  id="dmvText"><img  src="https://t.womtp.com/slider/c/delio-lm/dfi/assets/img/phone_alone_cmb.png" alt="Smart Buttom" style="">' + window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaWeCallYou + '</a>';
                        document.getElementsByTagName('body')[0].appendChild(dmv_bar);
                        document.getElementById("dmv_bar").innerHTML = dmv_bar1;
                        var dmvBarButton = document.getElementById("dmv_bar");
                        document.getElementById("dmvText").style.width = "100%";
                        dmvBarButton.onclick = function () {
                            if (DFI.ModalView !== undefined) {
                                DFI.ModalView.open();
                            }
                        };
                    }
                }
                //////////////

                if (window.DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaY === "top1") {
                    dmvBarButton.style.top = "0";
                } else {
                    dmvBarButton.style.bottom = "0";
                }
            }
        }

        if ( typeof DFI_client  === "object" &&  typeof DFI_client.validScheduleChat === "object" && DFI.scheduleValidAll(DFI_client.validScheduleChat)){
            statusDFI.personalizedChatScheduleValid = true;
        } else{
            statusDFI.personalizedChatScheduleValid = false;
        }
        if(typeof DFI_client.validScheduleChat === "object"){
            statusDFI.personalizedChatScheduleSet = true;
        }

        if(typeof statusDFI.personalizedChatScheduleSet ==="undefined" || statusDFI.personalizedChatScheduleValid ){
            if (window.DFI.resultShowRules.actionIsOkShowChat 
                && window.DFI.DFI_configuration.services.serviceList.webChat !== undefined 
                && window.DFI.DFI_configuration.services.serviceList.webChat !== "undefined" 
                && (window.DFI.DFI_configuration.services.serviceList.webChat.active === true 
                    || window.DFI.DFI_configuration.services.serviceList.webChat.active === 'true')) {
    
                if (window.DFI.resultShowRules.dsaId !== undefined &&
                    window.DFI.resultShowRules.dsaId !== null &&
                    window.DFI.resultShowRules.dsaId !== -1 &&
                    window.DFI.resultShowRules.dsaId !== "-1" &&
                    window.DFI.resultShowRules.dsaId !== "undefined" &&
                    window.DFI.resultShowRules.dsaId !== "null" &&
                    DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaType !== "delio") {
                    //Para que el chat reconozca el selector antes de la configuración
                    var btnsdChatUp = document.querySelectorAll('.dChatUp');
    
                    for (var i = 0; i < btnsdChatUp.length; i++) {
                        DFI.Chat_config.customDSA = btnsdChatUp[i];
                    }
                }
    
    
                DFI.chatConfiguration();
                //Para el caso de que tengamos multiDSA el DSA por defecto del chat se quita.
                if (window.DFI.resultShowRules.dsaId !== undefined &&
                    window.DFI.resultShowRules.dsaId !== null &&
                    window.DFI.resultShowRules.dsaId !== -1 &&
                    window.DFI.resultShowRules.dsaId !== "-1" &&
                    window.DFI.resultShowRules.dsaId !== "undefined" &&
                    window.DFI.resultShowRules.dsaId !== "null" &&
                    DFI.DFI_configuration.DSAs[window.DFI.resultShowRules.dsaId - 1].config.dsaType !== "delio") {
                    // DFI.Chat_config.autoStart = false;
                    //Eliminar cuando se suprima en el chat la varible global
                    window.Chat_config = DFI.Chat_config;
    
                    var createdDSAcheck = setInterval(function () {
    
                        if ((typeof SIO !== "undefined") && statusDFI.createdDSA === true) {
                            // Clase para levantar el chat .dChatUp
                            clearInterval(createdDSAcheck);
                            var btnsdChatUp = document.querySelectorAll('.dChatUp');
    
                            for (var i = 0; i < btnsdChatUp.length; i++) {
                                btnsdChatUp[i].addEventListener('click', function () {
    
                                    // if(!window.SIO.Chat.initialized ){
                                    //     SIO.Chat.start();
                                    // }
    
                                    // SIO.Chat.show();
                                    // if(document.getElementById("dsa") !== null){
                                    //     document.getElementById("dsa").style.display ="none";
    
                                    //     document.getElementById("sio-chat-close").addEventListener("click",function(){
                                    //         document.getElementById("dsa").style.display ="block";
                                    //         document.getElementById("sio-sa").style.display ="none";
    
                                    //     },false)    
                                    // }
                                }, false);
                            }
    
                        }
    
                    }, 66);
    
                }
                DFI.loadScript(DFI.global.dal, function () {
                    var chatScriptPromise = DAL.Ajax({
                        type: 'script',
                        charset: 'UTF-8',
                        url: DFI.global.chat
                    });
    
                    DAL.Promise.all([chatScriptPromise]).done(function () {
                        statusDFI.initChat = true
                    });
                });
            }
        }


        DFI.initC2C();
        statusDFI.initC2C = true;
        var visibleElement = true;
        if (window.DFI.DFI_configuration.services.serviceList.ringPool.ringpoolClass != undefined
            && window.DFI.DFI_configuration.services.serviceList.ringPool.ringpoolClass != "") {
            visibleElement = !DFI.isHidden(document.querySelectorAll(window.DFI.DFI_configuration.services.serviceList.ringPool.ringpoolClass));
        }


        //&& document.querySelectorAll(window.DFI.DFI_configuration.services.serviceList.ringPool.ringpoolClass).length > 0 
        if ((DFI.scheduleValidAll(window.DFI.validSchedule) || DFI.scheduleValidAll(DFI_client.validSchedule))
            && (window.DFI.resultShowRules.actionIsOkInterferePhone || window.DFI.DFI_configuration.services.serviceList.ringPool.active)
            && visibleElement
        ) {
            if (typeof DRP !== "undefined") {
                DFI.refreshRingpool();
            }else{
                DFI.chooseRingPool();
            }
           
           
            statusDFI.initRingpool = true;
        }

        //&& document.querySelectorAll(window.DFI.DFI_configuration.services.serviceList.ringPool.ringpoolClass).length === 0 
        if ((DFI.scheduleValidAll(window.DFI.validSchedule) || DFI.scheduleValidAll(DFI_client.validSchedule))
            && (window.DFI.resultShowRules.actionIsOkInterferePhone || window.DFI.DFI_configuration.services.serviceList.ringPool.active)
            && !visibleElement
        ) {
            //var MutationObserverName ="checkClass";
            //Check class that is hidden the element
            var elementToObserveNow = DFI.checkParentHidden(document.querySelector(window.DFI.DFI_configuration.services.serviceList.ringPool.ringpoolClass))
            //DFI.startMutationObserverIsChangedElementInDom(document.querySelector(window.DFI.DFI_configuration.services.serviceList.ringPool.ringpoolClass), DFI.ringPollClassIsVisible);
            DFI.checkClass = new MutationObserver(DFI.ringPollClassIsVisible);
            DFI.checkClass.observe(elementToObserveNow,
                {
                    attributes: true,
                    characterData: true,
                    childList: true,
                    subtree: true,
                    attributeOldValue: true,
                    characterDataOldValue: true
                });

        }


    }

    start();




    function start() {
        DFI.setDataLayer();
        statusDFI.setDataLayer = true;
        DFI.setUtmsVars();
        statusDFI.setUtmsVars = true;
        DFI.setTrackingVars();
        statusDFI.setTrackingVars = true;
        DFI.setConversionsLabels();
        statusDFI.setConversionsLabel = true;
        DFI.setDeclaratives();
        statusDFI.setDeclaratives = true;
        DFI.dataSessionStore();
        statusDFI.dataSessionStore = true;
        DFI.setLandingCustomVars();
        statusDFI.setLandingCustomVars = true;

        //Clear history

        if (DFI.DFI_configuration.recalcRuleShowInAllPages === true) {
            sessionStorage.removeItem("delioSessionData");
        }

        // Carga del Pixel de Visita
        var visitPromise = DAL.Ajax({
            type: "script",
            url: DFI.global.womtpPixels + window.DFI.DFI_configuration.idTagVisit
        }).done(function () {
            statusDFI.visitTrackLoad = true;
        });
        var counter = 0;
        var CheckVisitPromise = setInterval(function () {

            if (statusDFI.visitTrackLoad != true && counter > 1) {
                clearInterval(CheckVisitPromise);
                var tagVisitTemp = window.DFI.DFI_configuration.idTagVisit;
                DAL.Ajax({
                    type: "script",
                    url: DFI.global.womtpPixels + tagVisitTemp
                })
                visitPromise.resolve();
            } else {
                counter++;
                if (counter > 3) {
                    clearInterval(CheckVisitPromise);
                }
            }

        }, 400)


        // Carga de ModalView Script
        var dmvScriptPromise = DAL.Ajax({ type: "script", url: DFI.global.dmv });

        // Carga de ModalView Styles
        var dmvStylesPromise = DAL.Ajax({ type: "style", url: DFI.DFI_configuration.defaultStyles });


        // Carga de ModalView Styles
        var dsaScriptPromise = DAL.Ajax({ type: "script", url: DFI.global.dsa });

        // Carga de ModalView Styles
        DAL.Promise.all([visitPromise, dmvScriptPromise, dmvStylesPromise, dsaScriptPromise]).done(function () {
            //Carga librería tracking pasarelas: https://ws.walmeric.com/static/dft/dist/v1/det.js
            if (window.DET) {
                DFI.consoleLogDelio("Carga tracking pasarelas");
                statusDFI.gatewaysLoaded = true;
            }

            // Servicios de llamada
            window.DFI.servicioActivo1 = DFI.setRingPoolServicesNew();
            statusDFI.callServicesLoaded = true;

            // En caso de que el front no tenga asignado servicio de llamada, y para no provocar errores, asignamos servicios ficticios, para que en el detalle del lead
            // aparezca la fuente del error.
            if (window.DFI.servicioActivo1.serviceIdCallMeBack != undefined) {
                window.DFI.servicioActivo = window.DFI.servicioActivo1.serviceIdCallMeBack;
            } else {
                window.DFI.servicioActivo = "wrong service, check front configuration";
            }

            if (window.DFI.servicioActivo1.priority != undefined) {
                window.DFI.servicioActivo2 = window.DFI.servicioActivo1.priority;
            } else {
                window.DFI.servicioActivo2 = "wrong service, check front configuration";
            }


            if (window.DFI.servicioActivo1.ruleId != undefined) {
                window.DFI.servicioActivo3 = window.DFI.servicioActivo1.ruleId;
            } else {
                window.DFI.servicioActivo3 = "wrong service, check front configuration";
            }

            if (window.DFI.servicioActivo1.active != undefined) {
                window.DFI.servicioActivo4 = window.DFI.servicioActivo1.active;
            } else {
                window.DFI.servicioActivo4 = "wrong service, check front configuration";
            }

            if (window.DFI.servicioActivo1.countryCallMeBack != undefined) {
                window.DFI.servicioPais = window.DFI.servicioActivo1.countryCallMeBack.toLowerCase();
            } else {
                window.DFI.servicioPais = "wrong service, check front configuration";
            }


            //Ordenamos el objeto modals y DSAs, debería de llegar ordenado pero como no está controlado lo gestionamos desde aquí

            DFI.DFI_configuration.modals = DFI.DFI_configuration.modals.sort(DFI.dynamicSort("id"));
            DFI.DFI_configuration.DSAs = DFI.DFI_configuration.DSAs.sort(DFI.dynamicSort("id"));




            DFI.smartScoringShowRulesNewWay(function (respuestaLeadDelio1) {
                DFI.consoleLogDelio(respuestaLeadDelio1);
                DFI.ChecklistPromise = new Promise(function (resolve, reject) {
                    for (var i = 0; i < window.DFI.DFI_configuration.showRules.length; i++) {
                        if (window.DFI.DFI_configuration.showRules[i].rules &&
                            window.DFI.DFI_configuration.showRules[i].rules.list !== null &&
                            window.DFI.DFI_configuration.showRules[i].rules.list.output != "not_applied") {

                            let idConditionType = window.DFI.DFI_configuration.showRules[i].rules.list.idConditionType;
                            let product = window.DFI.DFI_configuration.showRules[i].rules.list.hashList;
                            let value = "";

                            switch (window.DFI.DFI_configuration.showRules[i].rules.list.typeLabel) {
                                case "dec":
                                    value = DFI.labels.EDeclaratives[window.DFI.DFI_configuration.showRules[i].rules.list.internalName];
                                    break;
                                case "vis":
                                    value = DFI.labels.EVisits["v_var_" + window.DFI.DFI_configuration.showRules[i].rules.list.idLabel];
                                    break;
                                case "con":
                                    value = DFI.labels.EConversion["c_var_" + window.DFI.DFI_configuration.showRules[i].rules.list.idLabel];
                                    break;

                                default:
                                    value = ""
                            }
                            if (value !== undefined) {

                                let currentIndex = i;
                                DAL.Ajax({
                                    type: "jsonp",
                                    url: DFI.global.checkList + DFI.idTagDelio + "&idList=" + product + "&value=" + value
                                }).done(function (response) {
                                    var output = false;
                                    if (response.exist === true) {
                                        if (idConditionType === 1) {
                                            output = true;
                                        }
                                    } else {
                                        if (idConditionType !== 1) {
                                            output = true;
                                        }
                                    }
                                    window.DFI.DFI_configuration.showRules[currentIndex].rules.list.output = output;
                                });
                            } else {
                                window.DFI.DFI_configuration.showRules[i].rules.list = { output: "not_applied" };
                            }
                        } else {
                            window.DFI.DFI_configuration.showRules[i].rules.list = { output: "not_applied" };
                        }
                    }

                    if (i === window.DFI.DFI_configuration.showRules.length) {
                        resolve("List completed");
                    }
                    else {
                        reject(Error("List error"));
                    }
                });



                DFI.ChecklistPromise.then(function () {
                    // Inicialización del Formulario
                    DFI.initFormsAndResources();
                });

            });



        });
        //Vemos si hay mapeado de JSON variable. De momento solo comprobamos variable Javascript

        if (DFI.DFI_configuration.mappingLabels !== null && DFI.DFI_configuration.mappingLabels.json !== null && DFI.DFI_configuration.mappingLabels.json.container === "1") {
            //debugger;
            //Habilitamos el observador 
            DFI.objectObserver();
            // Indicamos el objeto a observar. 
            if (typeof window[DFI.DFI_configuration.mappingLabels.json.objectName] !== "undefined") {
                DFI.WatchJS.watch(DFI.eval(DFI.DFI_configuration.mappingLabels.json.objectName), function () {
                    DFI.dynamicFormUpdate();
                });
            }
        }
    }

    /*
    |---------------------------------------------------------------------------
    | Obtener el template especificado.
    |---------------------------------------------------------------------------
    */
    DFI.getTemplate = function (name) {
        var templates = {};
        if (window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvModalType === "delio") {
            //Privacy modifications.
            var privacyBlock = "";
            if (typeof window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplatePrivacyText !== "undefined" &&
                window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplatePrivacyText !== "" &&
                window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplatePrivacyText !== undefined) {
                privacyBlock =
                    '<label id="aceptoCondiciones" class="dmv-legal" >' +
                    '<input class="dmv-legal-checkbox foo" type="checkbox" id="aceptoLegal" data-wg-validation="required" name="politica" value="si">' +
                    '<span class="dmv-span-condiciones">' +
                    '<a class="dmv-enlace-condiciones" href="' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplatePrivacyUrl + '" target="_blank" data-dmv-collapsable="elementoDesplegable"> ' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplatePrivacyText + '</a>' +
                    '</span>' +
                    '</label>';
            }

            if (typeof window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvArrTemplatePrivacyText !== "undefined") {
                for (var i = 0, len = window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvArrTemplatePrivacyText.length; i < len; i++) {
                    DFI.consoleLogDelio(window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvArrTemplatePrivacyText[i]);
                    var validation = "";
                    if (typeof window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvArrTemplatePrivacyText[i].mandatory && (window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvArrTemplatePrivacyText[i].mandatory === "true" || window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvArrTemplatePrivacyText[i].mandatory === true)) {
                        validation = "required";
                    }
                    privacyBlock += '<label id="aceptoCondiciones' + i.toString() + '" class="dmv-legal" >' +
                        '<input class="dmv-legal-checkbox foo" type="checkbox" id="aceptoLegal" data-wg-validation="' + validation + '" name="politica' + i.toString() + '" value="si">' +
                        '<span class="dmv-span-condiciones">' +
                        '<a class="dmv-enlace-condiciones" href="' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvArrTemplatePrivacyText[i].url + '" target="_blank" data-dmv-collapsable="elementoDesplegable"> ' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvArrTemplatePrivacyText[i].text + '</a>' +
                        '</span>' +
                        '</label>';
                }
            }

            templates = {

                desktopFueraHorario:
                    '<div id="dmv-contenedor" >' +
                    '<div id="WPHcabecera" class="DCS-headerForm">' +
                    '</div>' +
                    '<div class="DCS-bodyForm">' +
                    '<div id="title" class="dmv-title">' +
                    '<h2 class="dmv-h2">' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateTitle + '</h2>' +
                    '</div>' +
                    '<div id="title" class="dmv-title">' +
                    '<h3 class="dmv-h3">' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateSubTitle + '</h3>' +
                    '</div>' +
                    '</div>' +
                    '<form id="dmv-form" class="wg-form dmv-form">' +
                    '<div id="dmv-div-name-id" class="dmv-form-group dmv-div-nombre">' +
                    '<input type="text" class="dmv-input wg-input" id="nombre-DMV" name="nombre"  placeholder="' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplatePlaceholderName + '" data-wg-validation="required,text">' +
                    '</div>' +
                    '<div id="dmv-div-email-id" class="dmv-form-group dmv-div-email">' +
                    '<input type="text" class="dmv-input wg-input" id="dmv-email" name="Email"  placeholder="' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplatePlaceholderEmail + '" data-wg-validation="required,email">' +
                    '</div>' +
                    '<div class="dmv-form-group dmv-div-telefono">' +
                    '<input type="tel" class="dmv-input  wg-input" id="dmv-phone" name="phone"  placeholder="' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplatePlaceholderPhone + ' " data-wg-validation="required,phone">' +
                    '</div>' +
                    '<div>' +
                    '<button type="submit" class="btn btn-primary dmv-button wg-submit" id="btnSubmit" >' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.templateCallMeText + '</button>' +
                    '</div>' +
                    '<div class="clearBoth"></div>' +
                    '<div id="dmv-div-privacy-group" class="dmv-form-group">' +
                    privacyBlock +
                    '</div>' +
                    '<input class="wg-return" type="hidden" value="DCL_success">' +
                    '<input type="hidden" value="NO" name="verifyLeadId" class="wg-input">' +
                    '<input type="hidden" value="" name="c_var_1" class="wg-cvar">' +
                    '<input type="hidden" value="" name="c_var_2" class="wg-cvar">' +
                    '<input type="hidden" value="" name="c_var_3" class="wg-cvar">' +
                    '<input type="hidden" value="" name="c_var_4" class="wg-cvar">' +
                    '<input id="idservice" type="hidden" value="" name="service_call_assigned" class="wg-input">' +
                    '<input id="idservice1" type="hidden" value="" name="service_call_priority" class="wg-input">' +
                    '<input id="idservice2" type="hidden" value="" name="service_call_ruleId" class="wg-input">' +
                    '<input id="idservice3" type="hidden" value="" name="service_call_name" class="wg-input">' +
                    '<input id="idservice4" type="hidden" value="" name="visual_idRule" class="wg-input">' +
                    '<input id="idservice5" type="hidden" value="" name="visual_idOrder" class="wg-input">' +
                    '<input id="idservice6" type="hidden" value="" name="visual_idDescription" class="wg-input">' +
                    '</form>' +
                    '<div id="dmv-horario" class="dmv-description dmv-description-timetable">' +
                    window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateAditionalText +
                    '</div>' +

                    '</div>',

                desktop:
                    '<div id="dmv-contenedor" >' +
                    '<div id="WPHcabecera" class="DCS-headerForm">' +
                    '</div>' +
                    '<div class="DCS-bodyForm">' +
                    '<div id="title" class="dmv-title">' +
                    '<h2 class="dmv-h2">' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateTitle + '</h2>' +
                    '</div>' +
                    '<div id="title" class="dmv-title">' +
                    '<h3 class="dmv-h3">' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateSubTitle + '</h3>' +
                    '</div>' +
                    '</div>' +
                    '<div id ="llamanosId" class="llamanos">' +
                    '<p>' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateTextRingPool + " " +
                    '<a href="" class="drp-phone-new wal-drp-phone" id="ringPoolNumber"></a>' +
                    '</p>' +
                    '</div>' +
                    '<form id="dmv-form" class="wg-form dmv-form">' +
                    '<div id="dmv-div-name-id" class="dmv-form-group dmv-div-nombre">' +
                    '<input type="text" class="dmv-input wg-input" id="nombre-DMV" name="nombre"  placeholder="' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplatePlaceholderName + '" data-wg-validation="required,text">' +
                    '</div>' +
                    '<div id="dmv-div-email-id" class="dmv-form-group dmv-div-email">' +
                    '<input type="text" class="dmv-input wg-input" id="dmv-email" name="Email"  placeholder="' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplatePlaceholderEmail + '" data-wg-validation="required,email">' +
                    '</div>' +
                    '<div class="dmv-form-group dmv-div-telefono">' +
                    '<input type="tel" class="dmv-input wg-input" id="dmv-phone" name="phone"  placeholder="' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplatePlaceholderPhone + ' " data-wg-validation="required,phone">' +
                    '</div>' +
                    '<div>' +
                    '<button  type="submit" class="btn btn-primary dmv-button wg-submit" id="btnSubmit" >' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.templateCallMeText + '</button>' +
                    '</div>' +
                    '<div class="clearBoth"></div>' +
                    '<div id="dmv-div-privacy-group" class="dmv-form-group">' +
                    privacyBlock +
                    '</div>' +
                    '<input class="wg-return" type="hidden" value="DCL_success">' +
                    '<input type="hidden" value="NO" name="verifyLeadId" class="wg-input">' +
                    '<input type="hidden" value="" name="c_var_1" class="wg-cvar">' +
                    '<input type="hidden" value="" name="c_var_2" class="wg-cvar">' +
                    '<input type="hidden" value="" name="c_var_3" class="wg-cvar">' +
                    '<input type="hidden" value="" name="c_var_4" class="wg-cvar">' +
                    '<input id="idservice" type="hidden" value="" name="service_call_assigned" class="wg-input">' +
                    '<input id="idservice1" type="hidden" value="" name="service_call_priority" class="wg-input">' +
                    '<input id="idservice2" type="hidden" value="" name="service_call_ruleId" class="wg-input">' +
                    '<input id="idservice3" type="hidden" value="" name="service_call_name" class="wg-input">' +
                    '<input id="idservice4" type="hidden" value="" name="visual_idRule" class="wg-input">' +
                    '<input id="idservice5" type="hidden" value="" name="visual_idOrder" class="wg-input">' +
                    '<input id="idservice6" type="hidden" value="" name="visual_idDescription" class="wg-input">' +

                    '</form>' +
                    '<div id="dmv-horario" class="dmv-description dmv-description-timetable">' +
                    window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateAditionalText +
                    '</div>' +

                    '</div>',


                gracias:
                    '<div class="fade-layer info-layer dmv-contenedor">' +
                    '<div id="dmv-thanks"" class="dmv-title">' +
                    '<h2 class="dmv-h2">' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateGracias + '</h2>' +
                    '</div>' +
                    '<div id="dmv-thanks-fueraHorario" style="display:none;" class="dmv-title">' +
                    '<h2 class="dmv-h2">' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateGracias + '</h2>' +
                    '</div>' +
                    '</div>',

                gracias_fueraHorario:
                    '<div class="fade-layer info-layer dmv-contenedor">' +
                    '<div id="dmv-thanks-fueraHorario"" class="dmv-title">' +
                    '<h2 class="dmv-h2">' + window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateGracias + '</h2>' +
                    '</div>' +
                    '<span class="block horario" id="dmv-horario">' +
                    window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvTemplateAditionalText +
                    '</span>' +
                    '</div>',

                personalizedHtmlCodeModal: DFI.customElements.personalized.personalizedHtmlCodeModal,
                personalizedHtmlCodeThankYouPage: DFI.customElements.personalized.personalizedHtmlCodeThankYouPage,
                personalizedHtmlCodeModalOutSchedule: DFI.customElements.personalized.personalizedHtmlCodeModalOutSchedule,
                personalizedHtmlCodeThankYouPageOutSchedule: DFI.customElements.personalized.personalizedHtmlCodeThankYouPageOutSchedule,
            };
        } else {
            //Modales personalizadas
            templates = {

                desktop: window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvCustom.HTMLmodalInShedule,

                desktopFueraHorario: window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvCustom.HTMLmodalOutShedule,


                gracias: window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvCustom.HTMLthankyouInShedule,

                gracias_fueraHorario: window.DFI.DFI_configuration.modals[window.DFI.resultShowRules.modalIndex].config.dmvCustom.HTMLthankyouOutShedule,

                personalizedHtmlCodeModal: DFI.customElements.personalized.personalizedHtmlCodeModal,
                personalizedHtmlCodeThankYouPage: DFI.customElements.personalized.personalizedHtmlCodeThankYouPage,
                personalizedHtmlCodeModalOutSchedule: DFI.customElements.personalized.personalizedHtmlCodeModalOutSchedule,
                personalizedHtmlCodeThankYouPageOutSchedule: DFI.customElements.personalized.personalizedHtmlCodeThankYouPageOutSchedule,
            };

        }


        return templates[name];
    }

}