import './src/platform.js';
import './src/fingerprint2.js';
import { DFI } from './src/globalInit.js';
import { support } from './src/support.js';
import './src/options.js'; 


/**
 * Gestiona la carga del resto de las librerías.
 *
 */
function load() {

	// -------------------- Polyfill element.remove() -------------
	Element.remove = Element.remove || function() {
		this.parentElement.removeChild(this);
	}
	
	NodeList.prototype.remove = HTMLCollection.prototype.remove = function() {
		for(var i = this.length - 1; i >= 0; i--) {
			if(this[i] && this[i].parentElement) {
				this[i].parentElement.removeChild(this[i]);
			}
		}
	}
	// ------------------------------------------------------------
	
	let DFI = window.DFI;
	let idTagDelio = DFI.idTagDelio || window.idTagDelio;


	DAL.Promise.all([
		require("https://ws.walmeric.com/static/dft/dist/v1/dmv.js"),
		require("https://ws.walmeric.com/static/dft/dist/v1/dsa.js"),
		require("https://ws.walmeric.com/static/dft/dist/v1/det.js")
	]).done(launch);
}

/**
 * Lanza la ejecución del script
 *
 */
function launch() {
	let DFI = window.DFI;
	DFI.support = support;

	DFI.globalInit();
	if (DFI.getUrlVar("tokenContainer") !== "")  {
		var tokenContainer = DFI.getUrlVar("tokenContainer");
		var returnMeLocation = DFI.getUrlVar("returnMeLocation");
		var returnMeName = DFI.getUrlVar("returnMeName");
		DFI.eval = function (str, scope=window) {
			const keys = str.split(/[\.\[\]'"]/).filter(e => e);
			let ret = scope;
			
			try {
				keys.forEach(key => {
				ret = ret[key];
				});
				return ret;
			} catch(e) {
				return undefined;
			}
			}
		DFI.objectDataBack(tokenContainer, returnMeLocation, returnMeName);
		return;
	}


}

require('https://ws.walmeric.com/static/dft/dist/v1/dal.js').then(load); 